import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, get, child } from "firebase/database";

const app = initializeApp(
  {
    apiKey: "AIzaSyDQpBfiLba0HBHxRw5MXkFGym2Grd8yiDQ",
    authDomain: "mlb-bdata-sbx-d087.firebaseapp.com",
    databaseURL: "https://mlb-bdata-sbx-d087.firebaseio.com",
    projectId: "mlb-bdata-sbx-d087",
    storageBucket: "mlb-bdata-sbx-d087.appspot.com",
    messagingSenderId: "23971237154",
    appId: "1:23971237154:web:5c7c19cf8eb5bdcd71e97b",
    measurementId: "G-5TBKXKRLM4"
  }
);

export const writeData = async (userId, playerName, playerId, playersList, team) => {
    const db = getDatabase();
    if (playersList === undefined) {
        set(ref(db, 'user/' + userId), {
            players : [ {
                playerId : playerId,
                playerName: playerName,
                team: team,
            }]
        });
    } else {
        let list = playersList.players
        list.push({
            playerId : playerId,
            playerName: playerName,
            team: team,
        })
        set(ref(db, 'user/' + userId), {
            players : list
        });
    }
}

export const readData = (userId) => {
    const dbRef = ref(getDatabase());
    // let getChild = await get(child(dbRef, `test/${userId}`))
    // return getChild
    return get(child(dbRef, `user/${userId}`))
        .then((snapshot) => {
            if (snapshot.exists()) {
                return (snapshot.val());
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });
}