import './App.css';
import { useParams, Route, Routes } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import axios from 'axios';
import { TwitterTimelineEmbed, TwitterFollowButton, TwitterShareButton } from 'react-twitter-embed';
import { Player as VideoPlayer } from 'video-react';
import "video-react/dist/video-react.css";
import Headshot from '@mlb/react-patterns/dist/components/Headshot/headshot';
import Headline from '@mlb/react-patterns/dist/components/Headline/headline';
import TeamLogo from '@mlb/react-patterns/dist/components/TeamLogo/teamLogo';
import Table from '@mlb/react-patterns/dist/components/Table/table';
import { readData, writeData } from "./firebase"
import Popup from 'reactjs-popup';
import Theme from '@mlb/react-patterns/dist/components/Theme/theme';
import Icon from '@mlb/react-patterns/dist/components/Icon/icon';
import { Fireworks } from 'fireworks/lib/react'
import styled from 'styled-components';

const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background-color: #051430;
  color: white;

  font-size: 1em;
  border: none;
  border-radius: 3px;
`;


const PlayerStats = ({ playerGamelog, player, type }) => {
  let gameLogHeader;
  let gameLogBody = [];

  let seasonHeader;
  let seasonBody = [];

  let careerBody = [];

  
  if (player && playerGamelog) {
    const splits = playerGamelog[0]?.splits;
    if (player.primaryPosition.name === 'Pitcher') {
      gameLogHeader = [
        {
          content: 'Game Date',
          dataField: 'gameDate',
        },
        {
          content: 'Opponent',
          dataField: 'opponent',
        },
        {
          content: 'IP',
          dataField: 'ip',
        },
        {
          content: 'K',
          dataField: 'strikeouts',
        },
        {
          content: 'BB',
          dataField: 'baseOnBalls',
        },
        {
          content: 'H',
          dataField: 'hits',
        },
        {
          content: 'ER',
          dataField: 'earnedRuns',
        },
        {
          content: 'Batters Faced',
          dataField: 'battersFaced',
        },
      ];

      splits?.forEach(split => {
        gameLogBody.push({
          gameDate: split.date,
          opponent: split.opponent.abbreviation,
          ip: split.stat.inningsPitched,
          hits: split.stat.hits,
          strikeouts: split.stat.strikeOuts,
          baseOnBalls: split.stat.baseOnBalls,
          earnedRuns: split.stat.earnedRuns,
          battersFaced: split.stat.battersFaced,
        })
      });

      seasonHeader = [
        {
          content: 'GP',
          dataField: 'gamesPlayed',
        },
        {
          content: 'IP',
          dataField: 'ip',
        },
        {
          content: 'ERA',
          dataField: 'era',
        },
        {
          content: 'K',
          dataField: 'strikeOuts',
        },
        {
          content: 'BB',
          dataField: 'baseOnBalls',
        },
        {
          content: 'H',
          dataField: 'hits',
        },
        {
          content: 'WHIP',
          dataField: 'whip'
        },
        {
          content: 'CG',
          dataField: 'completeGames',
        }
      ];

      const seasonSplits = player.stats.find(stat => stat.type.displayName === 'season').splits[0];
      seasonBody = [
        {
          gamesPlayed: seasonSplits.stat.gamesPlayed,
          ip: seasonSplits.stat.inningsPitched,
          era: seasonSplits.stat.era,
          strikeOuts: seasonSplits.stat.strikeOuts,
          baseOnBalls: seasonSplits.stat.baseOnBalls,
          hits: seasonSplits.stat.hits,
          completeGames: seasonSplits.stat.completeGames,
          whip: seasonSplits.stat.whip,
        },
      ];

      const careerSplits = player.stats.find(stat => stat.type.displayName === 'career').splits[0];
      careerBody = [
        {
          gamesPlayed: careerSplits.stat.gamesPlayed,
          ip: careerSplits.stat.inningsPitched,
          era: careerSplits.stat.era,
          strikeOuts: careerSplits.stat.strikeOuts,
          baseOnBalls: careerSplits.stat.baseOnBalls,
          hits: careerSplits.stat.hits,
          completeGames: careerSplits.stat.completeGames,
          whip: careerSplits.stat.whip,
        },
      ];

    } else {
        // Game log
        // runs, hr, hits, abs, sbs, rbi, steals
        gameLogHeader = [
          {
            content: 'Game Date',
            dataField: 'gameDate',
          },
          {
            content: 'Opponent',
            dataField: 'opponent',
          },
          {
            content: 'At Bats',
            dataField: 'atBats',
          },
          {
            content: 'Hits',
            dataField: 'hits',
          },
          {
            content: 'RBIs',
            dataField: 'rbis',
          },
          {
            content: 'Home Runs',
            dataField: 'homeRuns',
          },
          {
            content: 'Steals',
            dataField: 'steals',
          },
        ];

        gameLogBody = [];
        splits?.forEach(split => {
          gameLogBody.push({
            gameDate: split.date,
            opponent: split.opponent.abbreviation,
            atBats: split.stat.atBats,
            hits: split.stat.hits,
            rbis: split.stat.rbi,
            homeRuns: split.stat.homeRuns,
            steals: split.stat.stolenBases,
          })
        });

        // season
        // gp, avg, obp, ops, rbi, double, triple, homeruns
        seasonHeader = [
          {
            content: 'GP',
            dataField: 'gamesPlayed',
          },
          {
            content: 'Avg',
            dataField: 'avg',
          },
          {
            content: 'OBP',
            dataField: 'obp',
          },
          {
            content: 'OPS',
            dataField: 'ops',
          },
          {
            content: 'RBIs',
            dataField: 'rbis',
          },
          {
            content: 'Doubles',
            dataField: 'doubles',
          },
          {
            content: 'Triples',
            dataField: 'triples',
          },
          {
            content: 'Home Runs',
            dataField: 'homeRuns',
          },
        ];

        const seasonSplits = player.stats.find(stat => stat.type.displayName === 'season')?.splits[0];
        if (seasonSplits) {
          seasonBody = [
            {
              gamesPlayed: seasonSplits.stat.gamesPlayed,
              avg: seasonSplits.stat.avg,
              obp: seasonSplits.stat.obp,
              ops: seasonSplits.stat.ops,
              rbis: seasonSplits.stat.rbi,
              doubles: seasonSplits.stat.doubles,
              triples: seasonSplits.stat.triples,
              homeRuns: seasonSplits.stat.homeRuns,
            },
          ];
        }
        

        const careerSplits = player.stats.find(stat => stat.type.displayName === 'career').splits[0];
        careerBody = [
          {
            gamesPlayed: careerSplits.stat.gamesPlayed,
            avg: careerSplits.stat.avg,
            obp: careerSplits.stat.obp,
            ops: careerSplits.stat.ops,
            rbis: careerSplits.stat.rbi,
            doubles: careerSplits.stat.doubles,
            triples: careerSplits.stat.triples,
            homeRuns: careerSplits.stat.homeRuns,
          },
        ];
    }
  }


  return (
    <Theme theme={{dark: false}}>
      <div style={{marginLeft: '1em'}}>
      {type !== 'Card' && <Headline level={3}>Stats</Headline>}
      <br />
      {gameLogHeader && gameLogBody && type === 'Page' && gameLogBody.length > 0 && <>
        <Headline level={6} >Game Log</Headline>
        <Table thead={gameLogHeader} tbody={gameLogBody} />
      </>}
      {seasonHeader && seasonBody && seasonBody.length > 0 && <>
        <Headline level={6}>Season</Headline>
        <Table thead={seasonHeader} tbody={seasonBody} />
      </>}
      {careerBody && seasonHeader && <>
        <Headline level={6}>Career</Headline>
        <Table thead={seasonHeader} tbody={careerBody} />
      </>}
    </div>   
    </Theme>
    
  );
}

const PlayerSprayCharts = ({id}) => {
  let chart;
  let title;
  if (id === '624413') {
    title = 'Spray Chart'
    chart = (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '0.5em', marginRight: '0.5em'}}>
        <iframe src="https://baseballsavant.mlb.com/illustrator/static?guid=d2c14337-6790-4e7a-8a85-dffc4d0f1d49&scale=true" width={'100%'} height={'500px'}></iframe>
      </div>
    );
  } else if (id === '605141') {
    title = 'Spray Chart'
    chart = (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '0.5em', marginRight: '0.5em'}}>
        <iframe src="https://baseballsavant.mlb.com/illustrator/static?guid=b6d1e60a-08df-4eb9-90f3-0cf3869afb32&scale=true" width={'100%'} height={'500px'}></iframe>
      </div>
    );
  } else if (id === '657277') {
    title = 'Heat Map'
    chart = (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '0.5em', marginRight: '0.5em'}}>
        <iframe src="https://baseballsavant.mlb.com/illustrator/static?guid=dbf449d1-810c-4c4b-8e55-1eed2dd0c2f2&scale=true" width={'100%'} height={'500px'}></iframe>
      </div>
    )
  } else if (id === '596129') {
    title = 'Spray Chart'
    chart = (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '0.5em', marginRight: '0.5em'}}>
        <iframe src="https://baseballsavant.mlb.com/illustrator/static?guid=43d6ee4e-6f91-4cc2-89b8-ed11d04e2555&scale=true" width={'100%'} height={'500px'}></iframe>
      </div>
    )
  } else if (id === '453286') {
    title = 'Heat Map'
    chart = (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '0.5em', marginRight: '0.5em'}}>
        <iframe src="https://baseballsavant.mlb.com/illustrator/static?guid=f4f2165e-2306-41ed-934e-4c5b35cb8b9a&scale=true" width={'100%'} height={'500px'}></iframe>
      </div>
    )
  } else {
    chart = <></>;
  }

  return (
    <div style={{marginLeft: '1em'}}>
      <Headline level={3}>{title}</Headline>
      {chart}
    </div>
  )

}

const PlayerVideos = ({ videos }) => {
  return videos ? (
    <>
    <div style={{marginLeft: '1em'}}>
      <Headline level={3}>Highlights</Headline>
    </div>
    {videos.map(video => {
      return  (
        <div style={{marginBottom: '1em', marginRight: '1em', marginLeft: '1em'}}>
          <p>{video.title}</p>
          <VideoPlayer
            playsInline
            poster="/assets/poster.png"
            src={video.url}
            fluid={'false'}
            width={"100%"} 
          />
        </div>
      )
     
    })}
    </>
  ): <></>
  
}

const PlayerSocial = ({ player }) => {
  return player ? (
    <div style={{marginLeft: '1em', marginRight: '1em'}}>
      <Headline level={3}>Twitter Feed</Headline>
      {player.social.twitter && 
       <TwitterTimelineEmbed
        sourceType="profile"
        screenName={player.social.twitter[0].replace('@','')}
        options={{height: 400, tweetLimit: 5}}
        tweetLimit={5}
      />
    }
    </div>
  ) : <></>
}

const UserList = ({ userList }) => {
  if (userList && userList.players) {
    let userHeader = [
      {
        content: 'Player Name',
        dataField: 'playerName',
      },
      {
        content: 'Team',
        dataField: 'team',
      },
      {
        content: 'Page',
        dataField: 'playerPage',
      },
      {
        content: 'Card',
        dataField: 'playerCard',
      },
      {
        content: 'Shop',
        dataField: 'shop',
      },
      {
        content: 'NFTs',
        dataField: 'nft'
      }
    ];

    let urlBase;

    if (window.location.hostname.includes('localhost')) {
       urlBase = 'http://' + window.location.hostname + ':' + window.location.port;
    } else {
      urlBase = 'https://' + window.location.hostname;
    }
  
    let userBody = []
    userList.players.forEach(player => {
      const playerNameSplit = player.playerName.split(' ');
      userBody.push({
        playerName: player.playerName,
        team: player.team,
        playerPage: <a href={`${urlBase}/${player.playerId}`}>Page</a>,
        playerCard: <a href={`${urlBase}/${player.playerId}/card`}>Card</a>,
        shop: <a href={`https://www.mlbshop.com/direct?query=${playerNameSplit[0]}%20${playerNameSplit[1]}`}><img src={'./shop.svg'} /></a>,
        nft: <a href={`https://mlb.candy.com/marketplace/search?q=${playerNameSplit[0]+playerNameSplit[1]}&tab=activeListings`}><img src={'./candy.svg'} /></a>,
      })
    })
    return userList && (
      <>
        <Table thead={userHeader} tbody={userBody} />
      </>
    )
  }
}

const NewPlayerModal = ({ open, id, onClose }) => {
  const url = `https://img.mlbstatic.com/mlb-photos/image/upload/h_400,q_auto/v1/people/${id}/action/vertical/current`;

  return (
    <Popup open={open} onClose={onClose} modal>
      <div style={{
        backgroundImage: `url(${url})`,
        height: '400px',
        width: '267px',
        borderRadius: '50px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
      >
        <div 
          style={{marginTop: 'auto', backgroundColor: 'white', borderRadius: '50px', fontSize: '0.75em', marginBottom: '0.75em', marginLeft: '0.5em', marginRight: '0.5em'}}
          onClick={() => window.open(`/${id}/card`)}
        >
          <p style={{marginBottom: '5px'}}>You've found a new player!</p> 
          <p style={{marginTop: '5px'}}>Click ~here~ to see their player card.</p>
        </div>
      </div>
    </Popup>  
  )
}

const PlayerSwipeable = () => {
  const { id } = useParams();

  const [player, setPlayer] = useState();
  const [playerVideos, setPlayerVideos] = useState();
  const [playerGamelog, setPlayerGameLog] = useState();
  const [userList, setUserList] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [showFireworks, setShowFireworks] = useState(false);
  const [colors, setColors] = useState();

  useEffect(() => {
    const getPlayer = async () => {
      const playerData = await axios.get(`https://statsapi.mlb.com/api/v1/people/${id}?hydrate=currentTeam,team,jobs,social,awards,stats(type=[season,career]),education,transactions`);
      const playerGamelog = await axios.get(`https://statsapi.mlb.com/api/v1/people/${id}/stats?stats=gameLog&gameType=R&sportId=1&season=2022&limit=3&hydrate=team`);
      const brandingData = await axios.get('https://storage.mobile.mlbinfra.com/atbatconfig/branding.json');

      const branding = brandingData.data.teams.find(team => team.teamID === playerData.data.people[0].currentTeam.id).teamColors;
      
      const colorsArray = [];

      Object.keys(branding).forEach(key => colorsArray.push(branding[key]));

      setColors(colorsArray);
      setPlayer(playerData.data.people[0]);
      setPlayerGameLog(playerGamelog.data.stats);

      let userList = await readData("tylerdonati")
      if (userList) {
        const seenPlayer = userList.players.map(player => player.playerId).includes(id);
        setModalOpen(!seenPlayer);
        if (!seenPlayer) {
          setShowFireworks(true);
        }
      }

      console.log(userList)
      if (userList !== undefined && userList.players !== undefined && userList.players.filter(player => player.playerId === id).length === 0) { 
        await writeData("tylerdonati", playerData.data.people[0].firstLastName, id, userList, playerData.data.people[0].currentTeam.abbreviation)
      } else if (userList === undefined || userList?.players === undefined || userList?.players?.length === 0) {
        console.log(userList)
        await writeData("tylerdonati", playerData.data.people[0].firstLastName, id, undefined, playerData.data.people[0].currentTeam.abbreviation)
      }
      userList = await readData("tylerdonati")
      setUserList(userList)

      const queryString = playerData.data.people[0].primaryPosition.name === 'Pitcher' 
        ? `PitcherID+=+${id}+AND+PitchResult+=\"swinging_strike\"+AND+PitchSpeed+>=+95+Order+By+Timestamp+DESC` 
        : `BatterID+=+${id}+AND+CaptivatingIndex+>=+70+Order+By+Timestamp+DESC`;
      var data = JSON.stringify({
        query: `query PlayerSearch($queryString: String!, $queryType: QueryType) {
                search(query: $queryString, queryType: $queryType) {
                  plays {
                    mediaPlayback {
                      title
                      description
                      date
                      feeds {
                        type
                        playbacks {
                          name
                          url
                        }
                      }
                    }
                  }
                }
              }`,
        variables: {"queryString": queryString,"queryType":"STRUCTURED"}
      });
      
      var config = {
        method: 'post',
        url: 'https://fastball-gateway.bdata-gcp.mlbinfra.com/graphql',
        headers: { 
          'Content-Type': 'application/json', 
          'Cookie': 'GCLB=CL-uoav8tYiEvgE'
        },
        data : data
      };
      const res = await axios(config);

      const videos = res.data.data.search.plays.map(data => {
        const title = data.mediaPlayback[0].title;
        const url = data.mediaPlayback[0].feeds.find(media => media.type === 'CMS' || media.type === 'HOME')?.playbacks.find(playback => playback.name === 'mp4Avc').url;

        return {
          title,
          url,
        }
      });
      setPlayerVideos(videos);
    }
    getPlayer();
  }, []);

  useEffect(() => {
    if (player) {
      document.title = player.firstLastName;
    }
  }, [player])

  let sprayChartPlayerIds = ['624413', '605141', '657277', '596129', '453286']

  const views = [
    <PlayerStats playerGamelog={playerGamelog} player={player} type={'Page'} />,
    <PlayerVideos videos={playerVideos}/>  
  ]

  if (sprayChartPlayerIds.includes(id)) {
    views.push(<PlayerSprayCharts id={id}/>)
  }

  if (player && player.social.twitter !== undefined) {
    views.push(<PlayerSocial player={player}/>)
  }


  const fxProps = {
    count: 3,
    interval: 200,
    colors: colors || [],
    calc: (props, i) => ({
      ...props,
      x: (i + 1) * (window.innerWidth / 2) - (i + 1) * 100,
      y: 200 + Math.random() * 100 - 50 + (i === 2 ? -80 : 0)
    })
  }

  return (
    <>
      <NewPlayerModal open={modalOpen} id={id}/>
      <div style={{display: 'flex', marginTop: '1em', marginLeft: '0.5em', marginBottom: '0.5em'}}>
        <Headshot playerId={id} type={'spots'} width={100}/>
        <div style={{display: 'flex', flexDirection: 'column', marginLeft: '0.5em'}}>
          <div style={{display: 'flex'}}>
            {player && <Headline level={2}>{player.firstLastName}</Headline>}
          </div>
          <div style={{display: 'flex', flexDirection: 'row', marginTop: '0.5em'}}>
            {player && <TeamLogo teamId={player.currentTeam.id} width={25}/>}
            {player && <Headline level={4}>{player.currentTeam.name}</Headline>}
          </div>
          {player && <Headline level={6}>{player.primaryPosition.name} | #{player.primaryNumber} | {player.birthDate} ({player.currentAge})</Headline>}
        </div>
      </div>

      {player && player.social.twitter ? (
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'}}>
            <TwitterFollowButton screenName={player.social.twitter[0].replace('@','')} />
          </div>
          ) : (
            <></>
          )}
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Button style={{marginRight: '1em'}} onClick={() => window.open(`/${id}/card`, '_self')}>Player Card</Button>
          <Button onClick={() => window.open('/collection', '_self')}>Collection</Button>
        </div>
        
      <hr />
      <SwipeableViews>
        {views}
      </SwipeableViews>
    </>
    
  )
}

const PlayerCard = () => {
  const { id } = useParams();

  const [playerGamelog, setPlayerGameLog] = useState();
  const [player, setPlayer] = useState();
  const [teamColor, setTeamColor] = useState();

  useEffect(() => {
    const getData = async () => {
      const playerData = await axios.get(`https://statsapi.mlb.com/api/v1/people/${id}?hydrate=currentTeam,jobs,social,awards,stats(type=[season,career]),education,transactions`);
      const playerGamelog = await axios.get(`https://statsapi.mlb.com/api/v1/people/${id}/stats?stats=gameLog&gameType=R&sportId=1&season=2022&limit=3&hydrate=team`);
      const branding = await axios.get('https://storage.mobile.mlbinfra.com/atbatconfig/branding.json');

      const color = branding.data.teams.find(team => team.teamID === playerData.data.people[0].currentTeam.id).teamColors.navigationColor;
      setTeamColor(color);
      setPlayer(playerData.data.people[0]);
      setPlayerGameLog(playerGamelog.data.stats);
    }
    getData();
  }, []);
  
  return player && playerGamelog ? (
      <div style={
      {
        backgroundImage: `url(https://img.mlbstatic.com/mlb-photos/image/upload/h_600,q_auto/v1/people/${id}/action/vertical/current)`,
        height: '99vh',
        width: '99vw',
        color: 'white',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        borderRadius: '50px',
        marginLeft: '0.5vw',
        marginTop: '0.5vh',
        textAlign: 'center',
      }
    }>
      <Theme theme={{dark: true}}>
        <Popup trigger={
          <div style={{display: 'flex'}}>
            <div style={{marginLeft: 'auto', marginRight: 'auto', display: 'flex'}}>
              <div style={{marginTop: 'auto', marginBottom: 'auto', marginRight: '0.5em'}}>
                <Icon name={'baseball'} size={'lg'} fill={teamColor}/>
              </div>
              <Headline level={1}>{player.firstLastName}</Headline>
            </div>
          </div>
        } modal closeOnDocumentClick>
          <div style={{backgroundColor: 'white', borderRadius: '50px'}}>
            <PlayerStats playerGamelog={playerGamelog} player={player} type={'Card'} />
          </div>
        </Popup>
      </Theme>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <img src={'https://upload.wikimedia.org/wikipedia/commons/2/29/Topps_Logo.svg'} style={{marginTop: '75vh', height: '50px', marginRight: 'auto', marginBottom: '0.5em'}}/>
        <div style={{marginTop: 'auto', display: 'flex', justifyContent: 'space-evenly'}}>
          <Button onClick={() => window.open('/collection', '_self')}>Collection</Button>
          <Button onClick={() => window.open(`/${id}`, '_self')}>Player Page</Button>
          <TwitterShareButton options={{text: `Check out this awesome player card for ${player.firstLastName}!`}}/>
        </div>
      </div>
    </div>
  ) : <></>
}

const UserListPage = () => {
  
  const [userList, setUserList] = useState();

  useEffect(() => {
    const getData = async () => {
      const userListData = await readData("tylerdonati")
      setUserList(userListData)
    };
    getData();
  });

  return userList ? (
    <Theme>
      <Headline level={1} style={{textAlign: 'center', marginTop: '0.5em'}}>Collection</Headline>
      <UserList userList={userList} />
    </Theme>
  ) : <></>
}


function App() {
  return (
      <Routes>
        <Route path="/:id" element={<PlayerSwipeable />} />
        <Route path="/:id/card" element={<PlayerCard />} />
        <Route path="/collection" element={<UserListPage />} />
      </Routes>
  );
}

export default App;
